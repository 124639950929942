import store from "@/state/store";
import { KcmContent, KcmNetworkOptions } from "@/types";
import { contentService } from "@/services/internal-api/content.service";
import { facebookService } from "@/services/socialmediaservice/facebook.service";
import axios from "axios";
import { b64toBlob } from "@/composables/helperUtils";
import { profileService } from "@/services/internal-api/profile.service";
import { igAccessScopes, facebookScopes } from "./helpers";
import { KcmSocialPromise, ServiceData } from "@/types/social-media.types";

const scopes = igAccessScopes.join(",");

const minimalPermissions = {
  scope: scopes,
  return_scopes: true,
};

const fullPermissions = {
  scope: facebookScopes.join(",") + "," + scopes,
  return_scopes: true,
};

declare interface ServiceResponse {
  data?: {
    response?: Response;
  };
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  error?: any;
}

declare interface Response {
  __usage__: {
    app: {
      call_count: number;
      total_time: number;
      total_cputime: number;
      type: string;
      estimated_time_to_regain_access: number;
    };
    page: {
      call_count: number;
      total_time: number;
      total_cputime: number;
      type: string;
      estimated_time_to_regain_access: number;
    };
    ad_account: {
      call_count: number;
      total_time: number;
      total_cputime: number;
      type: string;
      estimated_time_to_regain_access: number;
    };
    ads_insights: {
      app_id_util_pct: number;
      acc_id_util_pct: number;
    };
    business_use_case: unknown;
  };
  access_token: string;
  expires_in: number;
  token_type: string;
}

const serviceData: ServiceData = {
  initiated: false,
  pages: [],
};

export const instagramService = {
  name: "instagram",
  scopes,
  login,
  init,
  connect,
  disconnect,
  post,
  update,
  remove,
  getPages,
  checkAccess,
  serviceData,
};

function checkAccess(): boolean {
  const hasToken = store.getters["auth/getSocialToken"]("facebook");
  if (!hasToken) {
    return false;
  }
  return facebookService.checkStoreForMissingScopes(true) === "";
}

function buildTempIGUploadKey(): string {
  return `ig_tmp/${store.getters["auth/authUserId"]}${Date.now()}.jpg`;
}

function login(): Promise<KcmSocialPromise> {
  return new Promise((resolve) =>
    resolve({ success: false, error: "login is not functional for linkedin" })
  );
}

async function init(): Promise<boolean> {
  serviceData.initiated = await facebookService.init();
  return serviceData.initiated;
}

async function connect(): Promise<KcmSocialPromise> {
  let permissions = fullPermissions;
  if (store.getters["auth/getSocialToken"]("facebook")) {
    permissions = minimalPermissions;
  }
  return new Promise((resolve): void => {
    window.FB.login(async function (response: {
      error?: string;
      authResponse?: { userID: string; grantedScopes: string[] };
    }): Promise<void> {
      if (response.authResponse && response.authResponse.userID) {
        const authResponse = response.authResponse;
        const errorMessage = facebookService.checkForMissingScopes(
          authResponse,
          true
        );

        if (errorMessage) {
          resolve({
            success: false,
            error: errorMessage,
          });
        }

        let serviceResponse: ServiceResponse = {};
        serviceResponse = (await facebookService.facebookServicePost({
          access_token: window.FB.getAuthResponse()["accessToken"],
        })) as ServiceResponse;

        if (serviceResponse?.data?.response) {
          const email = store.getters["auth/authEmail"];
          facebookService.clearExpirationDateInCRM(email);
          resolve({
            success: true,
            data: {
              token: serviceResponse.data.response.access_token,
              id: window.FB.getAuthResponse()["userID"],
              scope: response.authResponse.grantedScopes,
            },
          });
        } else if (serviceResponse.error) {
          resolve({
            success: false,
            error: "There was an error getting a long lived token.",
          });
        }
      }

      resolve({
        success: false,
        error: "User closed Facebook login or did not fully authorize access.",
      });
    },
    permissions);
  });
}

async function disconnect(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    window.FB.api("/me/permissions/instagram_basic", "delete", {
      access_token: store.getters["auth/getServiceByField"](
        facebookService.name,
        "token"
      ),
    });
    window.FB.api("/me/permissions/instagram_content_publish", "delete", {
      access_token: store.getters["auth/getServiceByField"](
        facebookService.name,
        "token"
      ),
    });
    resolve({ success: true });
  });
}

async function post(
  content: KcmContent,
  shareType: string,
  networkOptions?: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  let endpoint = "";
  const userId = store.getters["auth/authUserId"];

  if (networkOptions === undefined || !networkOptions.id) {
    return new Promise((resolve) =>
      resolve({ success: false, error: "Missing network data" })
    );
  }

  if (
    ["blog", "slide", "social-graphics-single", "mmr", "local"].includes(
      shareType
    )
  ) {
    const imgBlob = b64toBlob(content.contents, "image/png");
    const filePath = `instagram/${buildTempIGUploadKey()}`;
    const tmpS3 = await profileService.memberImageUpload(
      userId,
      filePath,
      imgBlob
    );
    const imgPath = tmpS3.data.upload;
    endpoint =
      networkOptions.id + "/media?image_url=" + encodeURIComponent(imgPath);
  } else if (["videos"].includes(shareType)) {
    const thumbOffset = 4000;
    endpoint =
      networkOptions.id +
      "/media?media_type=REELS&video_url=" +
      encodeURIComponent(
        contentService.personalizedVideoUrl(content.contents)
      ) +
      "&thumb_offset=" +
      thumbOffset;
  } else if (shareType === "social-graphics-multi") {
    const images = content.contents.split(",");
    const containers = [];
    for (const image of images) {
      const imgBlob = b64toBlob(image, "image/png");
      const filePath = `instagram/${buildTempIGUploadKey()}`;
      const tmpS3 = await profileService.memberImageUpload(
        userId,
        filePath,
        imgBlob
      );
      const imgPath = tmpS3.data.upload;
      const createEndpoint =
        networkOptions.id + "/media?image_url=" + encodeURIComponent(imgPath);
      const accessToken = store.getters["auth/getServiceByField"](
        "facebook",
        "token"
      );
      const postResponse = await postToIg(accessToken, createEndpoint);
      if (
        postResponse.data &&
        postResponse.data.data &&
        postResponse.data.data.id
      ) {
        containers.push(postResponse.data.data.id);
      }
    }
    endpoint =
      networkOptions.id +
      "/media?media_type=CAROUSEL&children=" +
      encodeURIComponent(containers.join(","));
  } else if (["infographics"].includes(shareType)) {
    return new Promise((resolve) =>
      resolve({
        success: false,
        error:
          "Infographic dimensions are not acceptable for sharing to Instagram",
      })
    );
  } else {
    return new Promise((resolve) =>
      resolve({
        success: false,
        error: "Content type not set up to share via Instagram",
      })
    );
  }

  if (networkOptions.message) {
    endpoint += "&caption=" + encodeURIComponent(networkOptions.message);
  }

  const apiResp = await apiShare(networkOptions, endpoint);
  return apiResp;
}

async function update(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No update for instagram",
    });
  });
}

async function remove(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No remove for instagram",
    });
  });
}

async function apiShare(
  networkOptions: KcmNetworkOptions,
  createEndpoint: string
): Promise<KcmSocialPromise> {
  if (!networkOptions.id) {
    return new Promise((resolve) =>
      resolve({
        success: false,
        error: "No id provided for Instagram API Share",
      })
    );
  }

  const accessToken = store.getters["auth/getServiceByField"](
    "facebook",
    "token"
  );

  const limitCheck = await checkLimitReached(networkOptions.id, accessToken);
  if (limitCheck.error) {
    return limitCheck;
  }

  if (accessToken) {
    const postResponse = await postToIg(accessToken, createEndpoint);
    if (
      postResponse.data &&
      postResponse.data.data &&
      postResponse.data.data.id
    ) {
      return await attemptPublishToIg(
        accessToken,
        networkOptions.id,
        postResponse.data.data.id
      );
    }
    return postResponse;
  }
  return new Promise((resolve) =>
    resolve({
      success: false,
      error: "Something went wrong posting to Instagram",
    })
  );
}

async function getPages(): Promise<KcmSocialPromise> {
  const fields = ["name", "id", "instagram_business_account{username}"];
  return new Promise((resolve) => {
    window.FB.api(
      "/me/accounts?fields=" + fields.join(",") + "&limit=100",
      "GET",
      {
        access_token: store.getters["auth/getServiceByField"](
          "facebook",
          "token"
        ),
      },
      function (response: {
        data?: {
          instagram_business_account: { username: string; id: string };
        }[];
        error?: string;
      }) {
        if (response.error) {
          resolve({ success: false, error: response.error });
        }
        if (response.data !== undefined) {
          const finalResponse: { text: string; value: string }[] = [];
          Object.values(response.data).forEach(
            (page: {
              instagram_business_account?: { username: string; id: string };
            }): void => {
              if (page.instagram_business_account) {
                finalResponse.push({
                  text: page.instagram_business_account.username,
                  value: page.instagram_business_account.id,
                });
              }
            }
          );
          serviceData.pages = finalResponse;
          resolve({ success: true, data: finalResponse });
        }
      }
    );
  });
}

function postToIg(
  accessToken: string,
  endpoint: string
): Promise<KcmSocialPromise> {
  const postData = {
    access_token: accessToken,
  };
  return axios({
    method: "post",
    url: "https://graph.facebook.com/" + endpoint,
    data: postData,
  })
    .then((response: unknown) => {
      return {
        success: true,
        data: response,
      };
    })
    .catch((response) => {
      return {
        success: false,
        error:
          "Something went wrong when sharing to Instagram: " +
            response?.response?.data?.error?.message ?? "General Error",
      };
    });
}

async function attemptPublishToIg(
  accessToken: string,
  pageId: string,
  containerId: string
): Promise<KcmSocialPromise> {
  const containerEndpoint = "/" + containerId + "?fields=status_code,status";
  let checkResp = await checkContainerStatus(accessToken, containerEndpoint, 0);

  let attempt = 1;
  while (checkResp.success && checkResp.data !== "FINISHED") {
    await new Promise((r) => setTimeout(r, 5000 * attempt));
    checkResp = await checkContainerStatus(
      accessToken,
      containerEndpoint,
      attempt
    );
    attempt++;
  }
  if (!checkResp.success) {
    return checkResp;
  }

  const publishEndpoint =
    "/" + pageId + "/media_publish?creation_id=" + containerId;
  const publishResp = postToIg(accessToken, publishEndpoint);
  return publishResp;
}

async function checkContainerStatus(
  accessToken: string,
  containerEndpoint: string,
  attempt: number
): Promise<KcmSocialPromise> {
  if (attempt > 10) {
    return new Promise((resolve) =>
      resolve({
        success: false,
        error:
          "Post Failed. The Instagram API reached a time out when attempting to share this content. Try sharing directly via the Instagram app.",
      })
    );
  }

  return new Promise((resolve) => {
    window.FB.api(
      "https://graph.facebook.com/" + containerEndpoint,
      "GET",
      {
        access_token: accessToken,
      },
      async function (response: { status_code?: string }) {
        if (
          response.status_code === undefined ||
          response.status_code === "ERROR"
        ) {
          resolve({
            success: false,
            error:
              "Post Failed. There was an issue retrieving the status of the Instagram container.",
          });
        } else {
          resolve({
            success: true,
            data: response.status_code,
          });
        }
      }
    );
  });
}

async function checkLimitReached(
  pageId: string,
  accessToken: string
): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    window.FB.api(
      "/" + pageId + "/content_publishing_limit?fields=config,quota_usage",
      "GET",
      {
        access_token: accessToken,
      },
      function (response: {
        data?: {
          quota_usage: number;
          config: {
            quote_total: number;
          };
        }[];
        error?: string;
      }) {
        if (response.error || response.data === undefined) {
          resolve({ success: false, error: response.error });
        } else {
          if (
            response.data[0].quota_usage >= response.data[0].config.quote_total
          ) {
            resolve({
              success: false,
              error:
                "Quota reached. Instagram only allows 25 posts per 24 hour period.",
            });
          } else {
            resolve({ success: true });
          }
        }
      }
    );
  });
}
