
import appConfig from "@/app.config.json";
import {
  authComputed,
  profileComputed,
  settingsMethods,
} from "@/state/helpers";
import BackToTop from "@/components/widgets/back-to-top.vue";
import { defineComponent } from "vue";
import store from "@/state/store";
import axios from "axios";
import { socialMediaService } from "@/services/socialmediaservice/socialmedia.service";
import { SocialMediaServiceKey } from "./types/social-media.types";
import TeamImpersonationModal from "@/components/team-management/team-impersonation-modal.vue";

export default defineComponent({
  name: "App",
  components: {
    BackToTop,
    TeamImpersonationModal,
  },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title: string): string {
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  computed: {
    ...authComputed,
    ...profileComputed,
  },
  watch: {
    authUserId(): void {
      this.onResize();
    },
    profilePrimaryColor(): void {
      this.setProfileColor();
    },
  },
  mounted(): void {
    Object.keys(socialMediaService.services).forEach((media: string) => {
      socialMediaService.init(
        socialMediaService.services[media as SocialMediaServiceKey]
      );
    });

    this.setProfileColor();
    this.onResize();
    this.getSettingsConfig();

    /**
     * If there is a logged in user, let's call the auth store's identify function
     * This will run once here in case the user refreshes or loads a new page since
     * that wouldn't trigger the login action
     */
    if (this.authEmail) {
      store.dispatch("auth/identify");
    }

    // check if impersonating team member and show modal
    if (this.authTeamOwner) {
      this.$bvModal.show("team-impersonation-modal");
    }
  },
  created(): void {
    window.addEventListener("resize", this.onResize);
    this.attachGA4Script();
  },
  destroyed(): void {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...settingsMethods,
    setProfileColor(): void {
      document.body.style.setProperty(
        "--profileColor",
        "#" + this.profilePrimaryColor
      );
    },
    onResize(): void {
      this.setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    async getSettingsConfig(): Promise<void> {
      await axios
        .get(
          `${process.env.VUE_APP_SITE_CONFIG}${
            process.env.VUE_APP_INDUSTRY_ID || "1"
          }/config.json`
        )
        .then((response) => {
          if (response.data?.config) {
            this.setSiteConfig(response.data.config);
          }
        })
        .catch((e: any) => {
          console.log("Error loading config: ", e);
          return Promise.reject(e);
        });
    },
    attachGA4Script(): void {
      let script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.async = true;
      script1.src =
        "https://www.googletagmanager.com/gtag/js?id=" +
        process.env.VUE_APP_GA4_ID;
      document.head.appendChild(script1);

      let script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.innerHTML =
        "window.dataLayer = window.dataLayer || [];\n" +
        " function gtag() { dataLayer.push(arguments);}\n" +
        "gtag('js', new Date());\n" +
        "gtag('config', '" +
        process.env.VUE_APP_GA4_ID +
        "',\n" +
        " { send_page_view: false,\n " +
        "// Leave next comment for dev debugging purposes.\n" +
        "// debug_mode: true\n});";
      document.head.appendChild(script2);
    },
  },
});
