import { Route } from "vue-router/types/router";
import { KcmContentEvent } from "@/services/trackingservice/tracking.service";
import { AuthUser } from "@/types";

const ChurnZeroTracker = {
  name(): string {
    return "churnzero";
  },

  init(): void {
    window.ChurnZero = window.ChurnZero || [];
    (function (): void {
      const cz = document.createElement("script");
      cz.type = "text/javascript";
      cz.async = true;
      cz.src = `https://${process.env.VUE_APP_CHURNZERO_DOMAIN}.churnzero.net/churnzero.js`;
      const s = document.getElementsByTagName("script")[0];
      s.parentNode?.insertBefore(cz, s);
    })();

    window.ChurnZero.push(["setAppKey", process.env.VUE_APP_CHURNZERO_API_KEY]);
  },

  identify(user: Partial<AuthUser>): void {
    window.ChurnZero.push(["setContact", user.id ?? 0, user.id ?? 0]);
  },

  shouldTrackEngagement(contentType: string): boolean {
    return [
      "social-graphic",
      "video",
      "mmr",
      "newsletter - from content",
      "newsletter builder - pre-built",
      "newsletter builder - custom",
      "local",
    ].includes(contentType?.toLowerCase());
  },

  pageSpecificViews(): Record<string, string> {
    return {
      "Training Center": "Visited Training Center",
      "Best Practice": "Read Best Practice Article",
      "Help Center": "Visited Support Center",
    };
  },

  async event(name: string, description?: string): Promise<void> {
    if (window.enableKcmDebug) {
      console.log(`CZ event: ${name} ${description}`);
    }

    try {
      window.ChurnZero.push(["trackEvent", name, description]);
    } catch (e) {
      console.log("Failed to track CZ event" + e);
    }
  },

  pageView(data: { to: Route }): void {
    /**
     * If this page is one of the few we specifically track, per the list above
     * then call the function assigned to it from that list above
     */
    if (
      Object.keys(this.pageSpecificViews()).includes(data.to.name as string)
    ) {
      this.event(this.pageSpecificViews()[data.to.name as string]);
    }
  },

  login(): void {
    this.event("Login");
  },

  sharedContent(data: KcmContentEvent): void {
    this.event("Shared Content");
    this.event("Shared Content - " + data.contentType);

    if (this.shouldTrackEngagement(data.contentType)) {
      this.engagedContent(data);
    }
  },

  downloadedContent(data: KcmContentEvent): void {
    this.event("Downloaded Content");
    this.event("Downloaded Content - " + data.contentType);

    if (this.shouldTrackEngagement(data.contentType)) {
      this.engagedContent(data);
    }
  },

  scheduledContent(data: KcmContentEvent): void {
    this.event("Scheduled Post");
    this.event("Scheduled Post - " + data.contentType);

    if (this.shouldTrackEngagement(data.contentType)) {
      this.engagedContent(data);
    }
  },

  watchedContent(data: KcmContentEvent): void {
    this.event("Watched Content");
    this.event("Watched Content - " + data.contentType, data.description ?? "");

    if (this.shouldTrackEngagement(data.contentType)) {
      this.engagedContent(data);
    }
  },

  copiedContent(data: KcmContentEvent): void {
    const contentType = data.churnzeroContentType
      ? data.churnzeroContentType
      : data.contentType;
    this.event("Copied Content");
    this.event("Copied Content - " + contentType);

    if (this.shouldTrackEngagement(contentType)) {
      this.engagedContent(data);
    }
  },

  engagedContent(data: KcmContentEvent): void {
    this.event(data.contentType + " - Engaged");
  },

  modifiedZips(): void {
    this.event("local - Modified Zips");
  },

  /**
   * Automated Posts
   */
  autopostJobSetup(): void {
    this.event("Automation Set Up");
  },

  /**
   * Scriptbuilder
   */
  createdNewScript(): void {
    this.event("Script Builder - Engaged");
  },

  createdScriptFromScratch(): void {
    this.event("Script Builder - Create Custom Script");
  },

  createdScriptFromTemplate(): void {
    this.event("Script Builder - Create Template Script");
  },

  uploadedGraphic(): void {
    this.event("Script Builder - Uploaded Graphic");
  },

  clickedScriptEditorTool(data: string): void {
    this.event("Script Builder - Engaged with " + data);
  },

  insertedLocalAIBlock(): void {
    this.event("Script Builder - Insert Local AI Block");
  },

  /**
   * Webinars
   */
  signedUpForGSW(): void {
    this.event("Signed up for Getting Started Webinar");
  },

  /**
   * Profile
   */
  uploadedIntroOutro(): void {
    this.event("Video - Uploaded Intro/Outro");
  },

  /**
   * Interactive Tools
   */
  purchasingPower(): void {
    this.event("Purchasing Power Slide - Customized");
  },

  connectedSocial(data: { media: string }): void {
    this.event("Connected Social", data.media);
  },

  cancel(): void {
    this.event("Subscription - Cancel");
  },

  save(offerId: string): void {
    this.event("Subscription - Save");
    this.event("Subscription - Save - Offer - " + offerId);
  },
};

export default ChurnZeroTracker;
