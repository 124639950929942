import { KcmContent } from "@/types";
import store from "@/state/store";
import { KcmSocialPromise, ServiceData } from "@/types/social-media.types";

const serviceData: ServiceData = {
  initiated: false,
  pages: [],
};

export const pinterestService = {
  name: "pinterest",
  login,
  init,
  connect,
  disconnect,
  post,
  update,
  remove,
  getPages,
  serviceData,
  checkAccess,
};

function checkAccess(): boolean {
  return true;
}

function login(): Promise<KcmSocialPromise> {
  return new Promise((resolve) =>
    resolve({ success: false, error: "login is not functional for pinterest" })
  );
}

function init(): boolean {
  serviceData.initiated = true;
  return serviceData.initiated;
}

async function connect(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => resolve({ success: true }));
}

async function disconnect(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => resolve({ success: true }));
}

async function post(
  content: KcmContent,
  contentType: string
): Promise<KcmSocialPromise> {
  if (["blog", "videos"].includes(contentType)) {
    let contentUrl = store.getters["profile/profileSTMBlogLink"](
      content,
      store.getters["settings/contentLanguageSetting"]
    );

    if (contentType === "videos") {
      contentUrl = store.getters["profile/profileSTMLinkExtended"](
        "videos/" + content.slug,
        store.getters["settings/contentLanguageSetting"]
      );
    }

    const description =
      new DOMParser().parseFromString(content.description, "text/html").body
        .textContent || "";

    const url =
      "http://www.pinterest.com/pin-builder/?url=" +
      encodeURIComponent(contentUrl) +
      "&media=" +
      encodeURIComponent(content.featured_image) +
      "&description=" +
      encodeURIComponent(description);

    window.open(url, "sharer", "toolbar=0,status=0,width=700,height=500");
    return new Promise((resolve) => {
      resolve({
        success: true,
      });
    });
  } else {
    return new Promise((resolve) => {
      resolve({
        success: false,
        error: "Content type not set up to share via pinterest",
      });
    });
  }
}

async function update(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No update for pinterest",
    });
  });
}

async function remove(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No remove for pinterest",
    });
  });
}

async function getPages(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No function to get pages for pinterest",
    });
  });
}
