import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { userService } from "@/services/internal-api/auth.service";
import store from "@/state/store";

const routes: RouteConfig[] = [
  {
    path: "/",
    redirect: { path: "/dashboard" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      authRequired: true,
    },
    component: () => import("@/router/views/dashboards/default.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/router/views/login/login.vue"),
    meta: {
      beforeResolve(
        routeTo: Route,
        routeFrom: Route,
        next: NavigationGuardNext
      ): void {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next("/dashboard");
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("@/router/views/login/forgot-password.vue"),
    meta: {
      beforeResolve(
        routeTo: Route,
        routeFrom: Route,
        next: NavigationGuardNext
      ): void {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  { path: "/forgotpassword", redirect: "/forgot-password" },
  {
    path: "/logout",
    name: "logout",
    meta: {
      beforeResolve(
        routeTo: Route,
        routeFrom: Route,
        next: NavigationGuardNext
      ): void {
        store.dispatch("auth/logout");
        store.dispatch("profile/logout");
        store.dispatch("billing/logout");
        next({ name: "login" });
      },
    },
  },
  {
    path: "/setup-my-profile",
    name: "setup my profile",
    meta: { authRequired: true, noPPRequired: true },
    component: () => import("@/router/views/profile/setup-page.vue"),
    beforeEnter: async (
      to: Route,
      from: Route,
      next: NavigationGuardNext
    ): Promise<void> => {
      if (store.getters["profile/profileHasFlag"](1)) {
        return next("/dashboard");
      }
      next();
    },
  },
  {
    path: "/completeSignup",
    name: "Complete Signup",
    props: true,
    beforeEnter: async (
      to: Route,
      _: Route,
      next: NavigationGuardNext
    ): Promise<void> => {
      if (store.getters["auth/loggedIn"]) {
        if (store.getters["auth/authStatus"] !== "incomplete") {
          return next("/subscription");
        }
        await store.dispatch("billing/setBillingSource", "recurly");
        return next();
      }

      const v = to.query.v as string;
      if (v === undefined) {
        return next("/login");
      }

      const data = Buffer.from(v, "base64").toString().split(";");
      if (data.length !== 2) {
        return next("/login");
      }

      const user = data[0];
      const pass = data[1];

      await userService.login(user, pass);

      if (store.getters["auth/loggedIn"]) {
        if (store.getters["auth/authStatus"] !== "incomplete") {
          return next("/subscription");
        }
        await store.dispatch("billing/setBillingSource", "recurly");
        return next();
      }

      return next("/login");
    },
    component: () => import("@/router/views/settings/CompleteSignup.vue"),
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    props: true,
    beforeEnter: async (
      to: Route,
      _: Route,
      next: NavigationGuardNext
    ): Promise<void> => {
      if (store.getters["auth/loggedIn"]) {
        return next();
      }

      next();
    },
    component: () => import("@/router/views/settings/reset-password.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    meta: { authRequired: true },
    props: true,
    beforeEnter: async (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): Promise<void> => {
      if (
        !store.getters["auth/authIsCrew"] &&
        !store.getters["auth/authAdmin"]
      ) {
        return next("/dashboard");
      }

      next();
    },
    component: () => import("@/router/views/settings/admin-settings.vue"),
  },
  {
    path: "/too-many-redirects",
    name: "Something went wrong",
    meta: { authRequired: true },
    component: () => import("@/router/views/utility/404.vue"),
  },
  {
    path: "/confirm-delete",
    name: "Confirm Delete",
    props: true,
    component: () => import("@/router/views/utility/delete-account.vue"),
  },
  // 404
  {
    path: "/404",
    name: "Uh oh...",
    meta: { authRequired: true },
    component: () => import("@/router/views/utility/404.vue"),
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    redirect: "404",
  },
];

export default routes;
