import { Commit } from "vuex/types/index";

interface CRMState {
  lists: number[];
  loaded: boolean;
  loading: boolean;
}

const getEmptyState = (): CRMState => {
  return {
    lists: [],
    loaded: false,
    loading: false,
  };
};

export const state = getEmptyState();

export const getters = {
  inContactList:
    (state: CRMState) =>
    (listId: number): boolean => {
      return state.lists.includes(listId);
    },
  hasLoaded: (state: CRMState): boolean => {
    return state.loaded;
  },
  isLoading: (state: CRMState): boolean => {
    return state.loading;
  },
};
export const actions = {
  setContactLists({ commit }: { commit: Commit }, lists: number[]): void {
    commit("SET_CONTACT_LISTS", { lists: lists });
    commit("SET_LOADED", true);
  },

  addContactList({ commit }: { commit: Commit }, listId: number): void {
    const lists = state.lists.slice(0);
    lists.push(listId);
    commit("SET_CONTACT_LISTS", { lists: lists });
    commit("SET_LOADED", true);
  },

  setLoading({ commit }: { commit: Commit }, newValue: boolean): void {
    commit("SET_LOADING", newValue);
  },
};
export const mutations = {
  SET_CONTACT_LISTS(state: CRMState, value: { lists: number[] }): void {
    state.lists = value.lists;
  },

  SET_LOADED(state: CRMState, value: boolean): void {
    state.loaded = value;
  },

  SET_LOADING(state: CRMState, value: boolean): void {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
