import { KcmContentEvent } from "@/services/trackingservice/tracking.service";
import store from "@/state/store";
import router from "@/router";
import { Route } from "vue-router/types/router";
import { helpers } from "@/services/helpers";
import { KcmCategory, KcmContent } from "@/types";
let gtag: typeof window.gtag = null;
let userId = 0;

type GaEventAudiences = "buyers" | "sellers" | "buyers/sellers" | "";

interface GaEvent extends GaEventContent {
  action: string;
  section: Section;
  plan_tier?: string;
  category?: string;
  method?: string;
  query?: string;
  social_platform?: string;
}

type Section =
  | "dashboard"
  | "shareable-content"
  | "market-updates"
  | "kcm-local"
  | "realtalk-scripts"
  | "email-builder"
  | "resources"
  | "learning-center"
  | "(empty)";

interface GaEventContent {
  socialPlatform?: string;
  customization?: string;
  personalization?: string;
  file_extension?: string;
  file_name?: string;
  aspect_ratio?: string;
  slug?: string;
  debug_mode?: boolean;
  // new fields added 6/18/24, use underscores going forward
  content_id?: number;
  content_type?: string;
  content_language?: string;
  content_title?: string;
  content_audience?: GaEventAudiences;
  content_topics?: string;
  content_tags?: string;
  published_date?: string;
  updated_date?: string;
  page?: number;
  categories?: string;
  types?: string;
  query?: string;
  published_since?: string;
  video_length?: string;
  event_target?: string;
}

interface GaEventInputExtras {
  customization?: string;
  personalization?: string;
  method?: string;
  fileName?: string;
  videoLength?: number;
  aspectRatio?: string;
  eventTarget?: string;
}

const GoogleAnalytics = {
  name(): string {
    return "ga4";
  },

  /**
   * Sets up the gtag variable in this file
   */
  init(): void {
    try {
      gtag = window.gtag;
    } catch (err) {
      helpers.customSentryError("Failed to initialize GA", "", {
        error: err,
      });
    }
    this.checkUser();
  },

  checkUser(): void {
    if (userId !== parseInt(store.getters["auth/authUserId"])) {
      userId = parseInt(store.getters["auth/authUserId"]);
      try {
        if (userId === 0) {
          gtag("set", { user_id: null });
          gtag("set", "user_properties", {
            crm_id: null,
          });
        } else {
          gtag("set", { user_id: userId });
          gtag("set", "user_properties", {
            crm_id: userId,
          });
        }
      } catch (err) {
        helpers.customSentryError("Failed to setup user ID with GA", "", {
          error: err,
        });
      }
    }
  },

  event(data: GaEvent): void {
    try {
      if (gtag === null) {
        this.init();
      }

      const tier = store.getters["auth/getPlanSlug"];
      if (tier) {
        data.plan_tier = tier;
      }

      if (window.enableKcmDebug) {
        data.debug_mode = true;
        console.log("GA event: " + data.action + ": ", data);
      }

      gtag("event", data.action, data);
    } catch (err) {
      helpers.customSentryError("Failed to track event with GA", "", {
        error: err,
      });
    }
  },

  /**
   * Tracks a pageview in GA
   * @param to the path that was navigated to
   */
  pageView(data: { to: Route }): void {
    try {
      if (gtag === null) {
        this.init();
      } else {
        this.checkUser();
      }

      const eventData: {
        page_path: string;
        page_title?: string | null;
        plan_tier?: string;
      } = {
        page_path: data.to.fullPath,
        page_title: data.to.name,
      };

      const tier = store.getters["auth/getPlanSlug"];
      if (tier) {
        eventData.plan_tier = tier;
      }

      gtag("event", "page_view", eventData);
    } catch (err) {
      helpers.customSentryError("Failed to track pageview with GA", "", {
        error: err,
      });
    }
  },

  logout(): void {
    this.event({
      category: "Account",
      action: "Logout",
      section: this.getSection(),
    });
  },

  login(data: { method: string }): void {
    this.event({
      category: "Account",
      action: "login",
      method: data.method,
      section: this.getSection(),
    });
  },

  prepareContentEvent(
    content: Partial<KcmContent> | undefined,
    data: GaEventInputExtras,
    action: string
  ): GaEvent {
    const lang: string =
      store.getters["settings/contentLanguageSetting"] ?? "en";

    const eventData: GaEvent = {
      customization: data.customization,
      personalization: data.personalization,
      content_language: lang,
      action: action,
      section: this.getSection(),
    };

    if (content && content.id) {
      eventData.slug = content.slug;
      eventData.content_id = parseInt(content.id);
      eventData.content_type = content.content_type;
      eventData.content_title = content.title;
      eventData.content_audience = this.getAudiencesFromCategories(
        content.categories ?? []
      );
      eventData.content_topics =
        content.categories
          ?.map((category) => {
            return category.slug;
          })
          .join(",") ?? "";
      eventData.content_tags = content.tags?.join(",") ?? "";
      eventData.published_date = content.published_at;
      eventData.updated_date = content.updated_at;
    }

    if (["share", "schedule"].includes(action)) {
      eventData.socialPlatform = data.method ?? "";
    }

    if (action === "download") {
      //regex to grab the file extension from a string
      const result = data.fileName?.match(/([^/.]+)$|([^/]+)(\.[^/.]+)$/);
      eventData.file_extension = result?.[3] ?? "";
      eventData.file_name = data.fileName ?? "download";
    }

    if (data.videoLength) {
      eventData.video_length = this.convertSecondsToWritten(data.videoLength);
    }

    if (data.aspectRatio) {
      eventData.aspect_ratio = data.aspectRatio;
    }

    if (data.eventTarget) {
      eventData.event_target = data.eventTarget;
    }

    return eventData;
  },

  getAudiencesFromCategories(categories: KcmCategory[]): GaEventAudiences {
    const hasBuyers = categories.some((category) => category.slug === "buyers");
    const hasSellers = categories.some(
      (category) => category.slug === "sellers"
    );

    if (hasBuyers && hasSellers) {
      return "buyers/sellers";
    } else if (hasBuyers) {
      return "buyers";
    } else if (hasSellers) {
      return "sellers";
    }

    return "";
  },

  viewedContent(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(data.content, data, "view");
    this.event(eventData);
  },

  sharedContent(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(data.content, data, "share");
    this.event(eventData);
  },

  scheduledContent(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(data.content, data, "schedule");
    this.event(eventData);
  },

  downloadedContent(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(data.content, data, "download");
    this.event(eventData);
  },

  watchedContent(data: KcmContentEvent): void {
    //extract percentage for event name
    let eventName = "watch - unknown%";
    const match = data.description?.match(/\((\d+)%\)/);
    if (match && match[1]) {
      const percentage = parseInt(match[1], 10);
      eventName = `watch - ${percentage}%`;
    }
    // watch events require this janky stuff because of videos in the site that aren't technically content
    const contentEventData = this.prepareContentEvent(
      data.fullContent
        ? data.fullContent
        : {
            content_type: "other-video",
            title:
              data.description?.slice(0, data.description.lastIndexOf(",")) ??
              "",
            categories: [],
            published_at: "",
          },
      data,
      eventName
    );

    this.event(contentEventData);
  },

  copiedContent(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(data.content, data, "copy");
    this.event(eventData);
  },

  registerWebinar(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(
      data.content,
      data,
      "register_webinar"
    );
    this.event(eventData);
  },

  viewSTM(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(
      data.content,
      data,
      "View on STM"
    );
    this.event(eventData);
  },

  viewSpanish(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(
      data.content,
      data,
      "View Spanish"
    );
    this.event(eventData);
  },

  filteredContent(data: Record<string, never>): void {
    const lang: string =
      store.getters["settings/contentLanguageSetting"] ?? "en";

    const eventData: GaEvent = {
      page: data.page,
      categories: data.categories,
      content_tags: data.tags,
      types: data.types,
      query: data.query,
      content_language: lang,
      published_since: data.published_since,
      action: "filter",
      section: this.getSection(),
    };
    this.event(eventData);
  },

  searched(data: string): void {
    const lang: string =
      store.getters["settings/contentLanguageSetting"] ?? "en";

    const eventData: GaEvent = {
      query: data,
      content_language: lang,
      action: "search",
      section: this.getSection(),
    };
    this.event(eventData);
  },

  getSection(): Section {
    const currentRoutePath = router.currentRoute.path;
    const validSections: Section[] = [
      "dashboard",
      "shareable-content",
      "market-updates",
      "kcm-local",
      "realtalk-scripts",
      "email-builder",
      "resources",
      "learning-center",
    ];
    return (
      validSections.find((section) => currentRoutePath.includes(section)) ||
      "(empty)"
    );
  },

  playedAudio(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(
      data.content,
      data,
      "play_audio"
    );
    this.event(eventData);
  },

  convertSecondsToWritten(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.ceil(seconds % 60);

    const hourPart = hours > 0 ? `${hours} hour${hours !== 1 ? "s" : ""}` : "";
    const minutePart =
      minutes > 0 ? `${minutes} minute${minutes !== 1 ? "s" : ""}` : "";
    const secondPart =
      remainingSeconds > 0
        ? `${remainingSeconds} second${remainingSeconds !== 1 ? "s" : ""}`
        : "";

    const parts = [hourPart, minutePart, secondPart].filter(
      (part) => part !== ""
    );

    return parts.join(", ") || "0 seconds";
  },

  connectedSocial(data: { media: string }): void {
    this.event({
      action: "connect social media",
      section: this.getSection(),
      social_platform: data.media,
    });
  },

  startTestDrive(): void {
    this.event({ action: "start test drive", section: this.getSection() });
  },

  upload(event_target: string): void {
    const lang: string =
      store.getters["settings/contentLanguageSetting"] ?? "en";

    const eventData: GaEvent = {
      content_language: lang,
      action: "upload",
      event_target: event_target,
      section: this.getSection(),
    };
    this.event(eventData);
  },

  save(event_target: string): void {
    const lang: string =
      store.getters["settings/contentLanguageSetting"] ?? "en";

    const eventData: GaEvent = {
      content_language: lang,
      action: "save",
      event_target: event_target,
      section: this.getSection(),
    };
    this.event(eventData);
  },

  selectedContent(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(
      data.content,
      data,
      "select content"
    );
    this.event(eventData);
  },

  create(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(data.content, data, "create");
    this.event(eventData);
  },

  initiatePlanChange(): void {
    const eventData = {
      action: "initiate plan change",
      section: "(empty)" as Section,
    };
    this.event(eventData);
  },

  customize(data: KcmContentEvent): void {
    const eventData = this.prepareContentEvent(data.content, data, "customize");

    this.event(eventData);
  },
};

export default GoogleAnalytics;
