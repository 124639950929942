import { thirdPartyService } from "./../internal-api/thirdparty.service";
import store from "@/state/store";
import { KcmContent, KcmNetworkOptions } from "@/types";
import {
  LinkedInApiShareOptions,
  LinkedInApiShareResponse,
  LinkedInPageResponse,
} from "@/types/linkedin.types";
import axios, { AxiosResponse } from "axios";
import {
  KcmSocialPageData,
  KcmSocialPromise,
  ServiceData,
} from "@/types/social-media.types";

const BASE_URL = process.env.VUE_APP_SERVICES_API_ROOT
  ? process.env.VUE_APP_SERVICES_API_ROOT.replace(/\/$/, "")
  : "";
const HOST =
  BASE_URL +
  "/linkedin/v1/industries/" +
  (process.env.VUE_APP_INDUSTRY_ID ?? "1") +
  "/";

const serviceData: ServiceData = {
  initiated: false,
  pages: [],
};

export const linkedinService = {
  name: "linkedin",
  login,
  init,
  connect,
  getConnectLink,
  disconnect,
  post,
  update,
  remove,
  getPages,
  checkAccess,
  serviceData,
};

function checkAccess(): boolean {
  const hasToken = store.getters["auth/getSocialToken"]("linkedin");
  return hasToken !== null;
}

function login(): Promise<KcmSocialPromise> {
  return new Promise((resolve) =>
    resolve({ success: false, error: "login is not functional for linkedin" })
  );
}

function init(): boolean {
  serviceData.initiated = true;
  return serviceData.initiated;
}

async function connect(): Promise<KcmSocialPromise> {
  window.location.href = await getConnectLink();
  return { success: true };
}

async function getConnectLink(): Promise<string> {
  return new Promise((resolve) => {
    const queryVars = decodeURI(window.location.search);

    const extraChar = queryVars ? "&" : "?";

    const redirect =
      process.env.VUE_APP_URL +
      location.pathname.replace("/", "") +
      queryVars +
      extraChar +
      "media=LinkedIn&sync=services";

    const params = new URLSearchParams({
      userId: store.getters["auth/authUserId"],
      redirect: redirect,
      access_token: store.getters["auth/authToken"],
    });

    //get url to open from services api
    const authUrl = HOST + "oauth2/request_token?" + params.toString();

    resolve(authUrl);
  });
}

async function disconnect(): Promise<KcmSocialPromise> {
  saveExpirationDateInCRM("");
  return new Promise((resolve) => resolve({ success: true }));
}

async function post(
  content: KcmContent,
  shareType: string,
  networkOptions?: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  if (["blog", "videos"].includes(shareType)) {
    let contentUrl = store.getters["profile/profileSTMBlogLink"](
      content,
      store.getters["settings/contentLanguageSetting"]
    );

    if (shareType === "videos") {
      contentUrl = store.getters["profile/profileSTMLinkExtended"](
        "videos/" + content.slug,
        store.getters["settings/contentLanguageSetting"]
      );
    }
    simpleShare(contentUrl);
    return new Promise((resolve) =>
      resolve({ success: true, data: "Simple Share initiated" })
    );
  } else if (
    [
      "social-graphics-single",
      "infographics",
      "slide",
      "social-graphics-multi",
      "mmr",
      "local",
    ].includes(shareType)
  ) {
    const postData: LinkedInApiShareOptions = {
      token: store.getters["auth/getServiceByField"](
        linkedinService.name,
        "token"
      ),
      id: networkOptions?.id ?? "0",
      type: networkOptions?.linkedin_type ?? "person",
      text:
        networkOptions?.message && networkOptions.message !== ""
          ? networkOptions.message
          : ".",
    };
    postData.media = content.contents.split(",");
    const response = (await axiosPost("post", postData).catch((error) => {
      return error.response;
    })) as LinkedInApiShareResponse;

    if (response.data.message || !response.data.id) {
      let extendErr = "generic error";
      if (response.data.message !== "") {
        extendErr = response.data.message;
      }
      return new Promise((resolve) => {
        resolve({
          success: false,
          error: "Error posting to linkedin: " + extendErr,
        });
      });
    }

    return new Promise((resolve) => {
      resolve({
        success: true,
        data: response,
      });
    });
  }

  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "Content type not set up to share via linkedin",
    });
  });
}

async function update(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No update for linkedin",
    });
  });
}

async function remove(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No remove for linkedin",
    });
  });
}

function simpleShare(contentUrl: string): void {
  const url =
    "https://www.linkedin.com/sharing/share-offsite/?url=" +
    encodeURIComponent(contentUrl);

  window.open(url, "sharer", "toolbar=0,status=0,width=700,height=500");
}

async function getPages(): Promise<KcmSocialPromise> {
  const postData = {
    token: store.getters["auth/getServiceByField"](
      linkedinService.name,
      "token"
    ),
  };
  const response = (await axiosPost("pages", postData).catch((error) => {
    return error.response;
  })) as LinkedInPageResponse;

  if (response.data.message || !response.data.pages) {
    let extendErr = "generic error";
    if (response.data.message) {
      extendErr = response.data.message;
    }

    return new Promise((resolve) => {
      resolve({
        success: false,
        error: "Error getting handle from linkedin: " + extendErr,
      });
    });
  }

  const finalResponse: KcmSocialPageData[] = [];
  Object.values(response.data.pages).forEach(
    (page: { name: string; id: string }): void => {
      finalResponse.push({ text: page.name, value: page.id });
    }
  );

  serviceData.pages = finalResponse;

  return new Promise((resolve) => {
    resolve({ success: true, data: finalResponse });
  });
}

function saveExpirationDateInCRM(date: string): void {
  let formatted = "";

  if (date) {
    const unixtime = parseInt(date);
    const myDate = new Date(unixtime * 1000);
    formatted = myDate.toISOString().split("T")[0];
  }
  const email = store.getters["auth/authEmail"];

  thirdPartyService
    .post("/hubspot/v1/contact/" + email, {
      kcm_li_check_date: formatted,
    })
    .catch((err) => {
      console.log("Failed to save expiration date for LinkedIn in CRM: " + err);
    });
}

function axiosPost(
  resource: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  return axios.post(HOST + resource, data, { ...thirdPartyService.HEADERS() });
}
