import store from "@/state/store";
import { AuthUser, KcmContent } from "@/types";
import { Route } from "vue-router/types/router";

let trackers: KcmTracker[] = [];

export interface KcmContentEvent {
  content?: KcmContent;
  contentType: string;
  method?: string;
  fileName?: string;
  description?: string;
  customization?: string;
  personalization?: string;
  emailFormat?: string;
  fullContent?: KcmContent;
  videoLength?: number;
  aspectRatio?: string;
  eventTarget?: string;
  churnzeroContentType?: string;
}

export interface KcmTracker {
  identify?(user: Partial<AuthUser>): void;
  updateUserAttributes?(data: Record<string, unknown>): void;
  name(data?: Record<string, never>): string;
  pageView(data?: { to: Route }): void;
  login?(data: { method: string }): void;
  logout?(data?: Record<string, never>): void;
  viewedContent?(data: KcmContentEvent): void;
  sharedContent?(data: KcmContentEvent): void;
  scheduledContent?(data: KcmContentEvent): void;
  engagedContent?(data: KcmContentEvent): void;
  watchedContent?(data: KcmContentEvent): void;
  copiedContent?(data: KcmContentEvent): void;
  downloadedContent?(data: KcmContentEvent): void;
  signedUpForGSW?(data?: Record<string, never>): void;
  registerWebinar?(data: KcmContentEvent): void;
  uploadedIntroOutro?(data?: Record<string, never>): void;
  purchasingPower?(data?: Record<string, never>): void;
  autopostJobSetup?(data?: Record<string, never>): void;
  connectedSocial?(data: { media: string }): void;
  viewSTM?(data: KcmContentEvent): void;
  viewSpanish?(data: KcmContentEvent): void;
  cancel?(): void;
  save?(data: string): void;
  modifiedZips?(): void;
  createdNewScript?(data?: Record<string, never>): void;
  createdScriptFromScratch?(data?: Record<string, never>): void;
  createdScriptFromTemplate?(data?: Record<string, never>): void;
  uploadedGraphic?(data?: Record<string, never>): void;
  clickedScriptEditorTool?(data: string): void;
  insertedLocalAIBlock?(data?: Record<string, never>): void;
  filteredContent?(data?: Record<string, never>): void;
  searched?(data: string): void;
  playedAudio?(data: KcmContentEvent): void;
  startTestDrive?(): void;
  upload?(data: string): void;
  selectedContent?(data: KcmContentEvent): void;
  create?(data: KcmContentEvent): void;
  initiatePlanChange?(): void;
  customize?(data: KcmContentEvent): void;
  startCancel?(): void;
  acceptCancelSave?(data?: string): void;
  acceptCancelDeflect?(): void;
  closeCancel?(): void;
  declineCancelSave?(): void;
}

const TrackingService = {
  /* Returning the array of trackers. */
  listTrackers(): KcmTracker[] {
    return trackers;
  },

  /* Adding a new tracker to the array of trackers. */
  registerTracker(tracker: KcmTracker): void {
    trackers.push(tracker);
  },

  /* Removing a tracker from the array of trackers. */
  deregisterTracker(name: string): void {
    trackers = trackers.filter((t) => {
      return t.name() !== name;
    });
  },

  /* Looping through the array of trackers and calling the method that was passed in. */
  // eslint-disable-next-line
  trackEvent(label: keyof KcmTracker, data?: any): void {
    //if we're currently impersonating, skip event tracking
    if (store.getters["authAdmin"]) {
      return;
    }

    /**
     * adjust to consistent content type naming conventions
     */
    if (data?.contentType) {
      data.contentType = this.adjustContentType(data.contentType);
    }
    if (data?.content?.content_type) {
      data.content.content_type = this.adjustContentType(
        data.content.content_type
      );
    }

    //Loop through all the registered trackers
    trackers.forEach((tracker) => {
      tracker[label]?.(data ?? null);
    });
  },

  updateUserAttributes(data: Record<string, unknown>): void {
    if (store.getters["authAdmin"]) return;

    trackers.forEach((tracker: KcmTracker) => {
      tracker.updateUserAttributes?.(data);
    });
  },

  adjustContentType(contentType: string): string {
    //make lower case
    let cType = contentType.toLowerCase();

    //remove extra descriptors from content type
    cType = cType.replace(/(-schedule|-single|-multi)/, "");

    // remove trailing s
    cType = cType.replace(/s$/, "");

    return cType;
  },
};

export default TrackingService;
