import { getters as authGetters, state as authstate } from "./auth";
import i18n from "@/i18n";
import { SettingsStore, SiteConfig } from "@/types";
import { Commit } from "vuex";
import {
  insensitiveAssign,
  saveToLocalStorage,
} from "@/composables/helperUtils";

const defaultState: SettingsStore = {
  language: "en",
  contentLanguage: "en",
  window: {
    height: 0,
    width: 0,
  },
  adBlockEnabled: false,
  siteConfig: undefined,
};

const getDefaultState = (): SettingsStore => {
  const storageSettings = localStorage.getItem("settings");
  if (storageSettings && storageSettings !== "{}") {
    const storage = JSON.parse(storageSettings || "{}");
    i18n.locale = storage.language;
    storage.window = {
      height: 0,
      width: 0,
    };
    return storage;
  }

  return defaultState;
};

export const state = getDefaultState();

export const getters = {
  allSettings(state: SettingsStore): SettingsStore {
    return state;
  },
  languageSetting(state: SettingsStore): string {
    return state.language;
  },
  contentLanguageSetting(state: SettingsStore): string {
    return state.contentLanguage;
  },
  windowHeight(state: SettingsStore): number {
    return state.window.height;
  },
  windowWidth(state: SettingsStore): number {
    return state.window.width;
  },
  adBlockEnabled(state: SettingsStore): boolean {
    return state.adBlockEnabled;
  },
  siteConfig(state: SettingsStore): SiteConfig | undefined {
    return state.siteConfig;
  },
};

export const actions = {
  loadUserSettings({ commit }: { commit: Commit }): void {
    const userId = authGetters.authUserId(authstate);
    const storageSettings = localStorage.getItem("settings");
    if (storageSettings && storageSettings !== "{}") {
      commit("SETTINGS", {
        userId: userId,
        settings: JSON.parse(storageSettings),
      });
      return;
    }
    commit("SETTINGS", {
      userId: userId,
      settings: defaultState,
    });
    return;
  },
  changeSettings(
    { commit }: { commit: Commit },
    settings: SettingsStore
  ): void {
    const userId = authGetters.authUserId(authstate);
    commit("SETTINGS", {
      userId: userId,
      settings: settings,
    });
  },
  changeLanguageSetting(
    { commit }: { commit: Commit },
    language: string
  ): void {
    const userId = authGetters.authUserId(authstate);

    i18n.locale = language.toString();
    commit("LANGUAGE", {
      userId: userId,
      language: language,
    });
  },
  changeContentLanguageSetting(
    { commit }: { commit: Commit },
    contentLanguage: string
  ): void {
    const userId = authGetters.authUserId(authstate);

    // needed to resize masonry on content language changes
    dispatchEvent(new Event("resize"));

    commit("CONTENT_LANGUAGE", {
      userId: userId,
      language: contentLanguage,
    });
  },
  setWindowDimensions(
    { commit }: { commit: Commit },
    newValue: { width: number; height: number }
  ): void {
    commit("WIDTH", newValue.width);
    commit("HEIGHT", newValue.height);
  },
  changeAdBlockEnabled({ commit }: { commit: Commit }, enabled: boolean): void {
    commit("ADBLOCK_ENABLED", enabled);
  },
  setSiteConfig({ commit }: { commit: Commit }, config: SiteConfig): void {
    commit("SITE_CONFIG", config);
  },
};

function saveSettingsToLocal(): void {
  saveToLocalStorage("settings", state);
}

export const mutations = {
  SETTINGS(
    state: SettingsStore,
    newValue: { userId: string; settings: SettingsStore }
  ): void {
    if (newValue.settings === null) {
      Object.assign(state, defaultState);
    } else {
      Object.assign(state, insensitiveAssign(state, newValue.settings));
    }
    saveSettingsToLocal();
  },
  LANGUAGE(
    state: SettingsStore,
    newValue: { userId: string; language: string }
  ): void {
    state.language = newValue.language;
    saveSettingsToLocal();
  },
  CONTENT_LANGUAGE(
    state: SettingsStore,
    newValue: { userId: string; language: string }
  ): void {
    state.contentLanguage = newValue.language;
    saveSettingsToLocal();
  },
  HEIGHT(state: SettingsStore, newValue: number): void {
    state.window.height = newValue;
  },
  WIDTH(state: SettingsStore, newValue: number): void {
    state.window.width = newValue;
  },
  ADBLOCK_ENABLED(state: SettingsStore, newValue: boolean): void {
    state.adBlockEnabled = newValue;
  },
  SITE_CONFIG(state: SettingsStore, newValue: SiteConfig): void {
    state.siteConfig = newValue;
    saveSettingsToLocal();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
